import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Bag } from '@models/business/bag.model';

// MODELS
import { OrderMetadata } from '@models/business/order-metadata-manifest.model';
import { OrderMetadataManifestItem } from '@models/business/order-metadata-manifest-item.model';

@Component({
  selector: 'mobilus-bag',
  templateUrl: './bag.component.html',
  styleUrls: ['./bag.component.scss'],
})
export class BagComponent implements OnInit {

  isModalOpen = false;
  @Input() bag: Bag;
  @Input() metadata: OrderMetadata;

  public manifestItems: OrderMetadataManifestItem[];

  constructor() {
  }

  public ngOnInit(): void {
    // HOTFIX: seems ot be causing performances issues. Testing with that code disabled for the moment
    // this.manifestItems = this.metadata.manifest.filter((manifestItem: OrderMetadataManifestItem) => {
    //   return manifestItem.value === this.bag.bagType.name;
    // });
  }

  setOpen($event: Event, isOpen: boolean) {
    $event.stopPropagation();
    if (isOpen === true && !this.manifestItems) {
      this.manifestItems = this.metadata?.manifest?.filter((manifestItem: OrderMetadataManifestItem) => {
        return manifestItem.value === this.bag.bagType.name;
      });
    }
    this.isModalOpen = isOpen;
  }

}


import { Component, OnDestroy, OnInit } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DriverOwnedInfo} from '@models/business/driver-owned-info.model';
import {Order} from '@models/business/order.model';
import {UserSettings} from '@models/settings/settings.model';
import {Transition} from '@models/workflow/transition.model';
import {DriverNotesModalPage} from '@app/pages/driver-notes-modal/driver-notes-modal.page';
import { ModalController, NavParams } from '@ionic/angular';
import {MblsAnalyticsService} from '@services/mbls-analytics-service/MblsAnalyticsService';
import {LogService} from '@services/log/log.service';
import {MapService} from '@services/map/map.service';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import {OrderStoreService} from '@services/order-store/order-store.service';
import {TransitionService} from '@services/transition/transition.service';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { OrderStatus } from '@models/order-helper/order-status.enum';
import { OrderStatusConverter } from '@models/order-helper/order-status.enum';
import { Provider } from '@models/business/provider.model';

const CONFIG = {
  GA: {
    PAGE_NAME: 'OrderDetailPage',
    EVENT: {
      ORDER_TRANSITION: {
        NAME: 'transition_item',
        ACTION: 'button_pressed'
      }
    }
  }
};


@Component({
  selector: 'mobilus-order-detail',
  templateUrl: './order-detail.page.html',
  styleUrls: ['./order-detail.page.scss'],
})
export class OrderDetailPage implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject<void>();
  order: Order;
  transitions: Array<Transition>;
  settings: UserSettings = new UserSettings(true);
  reference: moment.Moment = moment();
  private timer: NodeJS.Timeout;

  isforHistory = false;
  public provider: Provider;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
              private transitionService: TransitionService,
              private mapService: MapService,
              private orderStoreService: OrderStoreService,
              private ga: MblsAnalyticsService,
              private mobileContextService: MobileContextService,
              private log: LogService,
              private sanitizer: DomSanitizer,
  ) {
    const view = this.navParams.get('orderView');
    this.order = view.order;
    // @note below is to test how phones are displayed on the page, can be removed if not needed
    // this.order.client.phoneNumbers = [{
    //   label: 'Mobile',
    //   number: '11111111'
    // }, {
    //   label: 'Phone',
    //   number: '222222'
    // }];
    this.isforHistory = this.navParams.get('isforHistory') || false;
  }

  ngOnInit(): void {
    this.mobileContextService.haulerObservable
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(
      hauler => {
        if (hauler) {
          const provider = hauler.linkedProviders
            .find(p => {
              return p.id.toString() === this.order.tenantId.toString();
            });

          if (provider) {
            this.provider = provider;
          }
        }
      });

    this.timer = setInterval(() => {
      this.reference = moment();
    }, 5000);

    this.mobileContextService.userSettingsObservable
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe((next) => {
        this.settings = next;
      });
    this.ga.trackView(CONFIG.GA.PAGE_NAME).catch(error => this.log.info(`Unable to track view ${CONFIG.GA.PAGE_NAME} with GA`, error));
  }


  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openInMap() {
    this.mapService.openMap(this.order);
  }

  public openMapForProvider(provider: Provider) {
    this.mapService.openMap(null, provider.position, provider.address.addressOneLine, provider.name);
  }

  async editDriverNotes() {
    if (!this.order.client.driverOwnedInfo) {
      this.order.client.driverOwnedInfo = new DriverOwnedInfo();
    }
    const modal = await this.modalCtrl
      .create({
        component: DriverNotesModalPage,
        componentProps: {order: this.order}
      });
    await modal.present();
  }

  /**
   * Method to check if the return information should be displayed
   * See https://mobilus.myjetbrains.com/youtrack/issue/CAPP-68
   * 
   * @param order order
   * @param provider hauler
   * @returns true if the return information should be displayed, otherwise false
   */
  public shouldDisplayReturnInformation(
    order: Order,
    provider: Provider
  ): boolean {
    if (!order && !provider?.address) {
      return false;
    }
    const orderStatus = OrderStatusConverter.convert(order.status);
    return orderStatus === OrderStatus.ON_THE_WAY_TO_PROVIDER;
  }

}

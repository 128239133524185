import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Provider} from '@models/business/provider.model';
import {OrderView} from '@models/order-helper/order-view.model';
import {UserSettings} from '@models/settings/settings.model';
import {MobileContextService} from '@services/mobile-configuration-service/mobile-context.service';
import * as moment from 'moment';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {OrderStatus} from '@models/order-helper/order-status.enum';
import {Traceability} from '@models/business/traceability.model';
import {WorkflowService} from '@services/workflow/workflow.service';
import {IconName} from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'mobilus-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
})
export class OrderItemComponent implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject<void>();

  @Input() reference: moment.Moment;
  @Input() orderView: OrderView;
  @Input() index: number;
  @Input() isforHistory = false;
  @Input() styleInvalidity = false;
  @Input() withDistance = false;
  @Input() orderStatus: OrderStatus;
  settings: UserSettings;
  deliveryOpeningTime: string;
  isAssignedToHauler = false;

  constructor(private mobileContextService: MobileContextService,
              private workflowService: WorkflowService) {
  }

  get isShortDisplay(): boolean {
    return this.settings.displayType;
  }

  get detailSize(): number {
    if (this.orderView.order.local) {
      return 3;
    }
    return 4;
  }

  get tagSize(): number {
    if (this.orderView.order.local) {
      return 7;
    }
    return 8;
  }

  get longColSize(): number {
    if (this.orderView.order && this.orderView.order.local) {
      return 5;
    }
    return 6;
  }

  ngOnInit(): void {
    combineLatest([
      this.mobileContextService.userSettingsObservable,
      this.mobileContextService.haulerObservable,
      this.mobileContextService.userProfileObservable
    ])
      .pipe(
        takeUntil(this.unsubscribe)
      )
      .subscribe(([userSettings, hauler, userProfile]) => {
        this.settings = userSettings;
        this.deliveryOpeningTime = this.getProviderOpeningTime(hauler.linkedProviders, this.orderView);
        if (this.orderStatus
          && (this.orderStatus === OrderStatus.READY_FOR_HAULAGE)
          && (this.orderView.order.haulerEmployeeId === userProfile.haulerEmployeeId)
        ) {
          this.isAssignedToHauler = true;
        }
      });
  }

  getProviderOpeningTime(providers: Array<Provider>, orderView: OrderView): string {
    if (providers && providers.length > 0 && orderView) {
      const provider = providers.find(instance => instance.id === orderView.order.tenantId);
      return provider && provider.configuration.deliveryOpeningTime;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  get displayProvider(): boolean {
    if (!this.orderView) {
      return false;
    }
    return this.orderView.order.stateMachineWorkflow === 'PICKUP';
  }

  getIcon(): IconName {
    if (this.orderView.order.status === 'TO_PROCESS_PROVIDER_RECURRING') {
      return null;
    }
    if (this.orderView.order.status === 'IN_PREPARATION') {
      return null;
    }
    if (this.orderView.order.status === 'CANCELLED') {
      return null;
    }
    return this.workflowService.getStateFromString(this.orderView.order.status, this.orderView.order.stateMachineWorkflow).icon as IconName;
  }

}

import {NotificationData} from '@models/push-messages/notification-data.model';
import {OrderMessage} from '@models/push-messages/order-message.model';
import {RemoteManagementCommandEnumConverter} from '@models/push-messages/remote-management-command.data.model';
import {RemoteManagementCommandEnum} from '@models/push-messages/remote-management-command.enum.model';
import {UserSettings} from '@models/settings/settings.model';
import {plainToClass} from '@utils/json-converter/json-converter';
import {MomentDateConverter} from '@utils/json-converter/json-converter';
import {JsonProperty} from 'json2typescript';
import {JsonObject} from 'json2typescript';
import * as moment from 'moment';

@JsonObject('MqttMessageModel')
export class MqttMessageModel {

  @JsonProperty('timestamp', MomentDateConverter, true)
  timestamp: moment.Moment = undefined;
  /* order data push */
  @JsonProperty('hasOrderData', Boolean, true)
  hasOrderData: boolean = undefined;
  @JsonProperty('isUserNotification', Boolean, true)
  isUserNotification: boolean = undefined;
  @JsonProperty('orderMessage', Object, true)
  orderMessageRaw: any = undefined;
  /* Remote management command */
  @JsonProperty('isRemoteManagementRequest', Boolean, true)
  isRemoteManagementRequest: boolean = undefined;
  @JsonProperty('remoteManagementCommand', RemoteManagementCommandEnumConverter, true)
  remoteManagementCommand: RemoteManagementCommandEnum = undefined;
  @JsonProperty('payload', Object, true)
  payload: any = undefined;
  @JsonProperty('notification', NotificationData, true)
  notification: NotificationData = undefined;
  @JsonProperty('userSettings', UserSettings, true)
  userSettings: UserSettings = undefined;

  private _orderMessage: OrderMessage;

  get orderMessage(): OrderMessage {
    if (!this._orderMessage && !this.orderMessageRaw) {
      return;
    }
    if (this._orderMessage) {
      return this._orderMessage;
    }

    try {
      this._orderMessage = plainToClass(OrderMessage, this.orderMessageRaw);
    } catch (error) {
      console.log(error);
      const object = JSON.parse(this.orderMessageRaw);
      this._orderMessage = plainToClass(OrderMessage, object);
    }
    return this._orderMessage;
  }

}
